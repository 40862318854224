import React from 'react';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {
  FilterTagWrapper,
} from './ShareBox.style';
import classnames from "classnames";
import SearchBox from "../uikit/search/index";
import createTooltip from '../uikit/tooltip/index';

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.togglerRef = React.createRef();
    this.menuRef = React.createRef();
    this.state = {
      users: props.users || [],
      usersMap: props.usersMap || [],
      dropdownKey: Date.now()
    }

    this.tooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => {
        return (d || {}).id || '';
      },
      directionFunc: 'top'
    });
  }

  render() {
    const {data, disabled, allowExternal, excludeRole, advertiser_id, searchWithoutKeyword} = this.props;
    const {users, usersMap, dropdownKey} = this.state;
    return (
        <FilterTagWrapper className={this.props.className}>
          <div className="tag-list">
            {
              users.map((d, index) => {
                let user = (usersMap || []).find(x => x.id === d) || {name: d, id: d};
                user.name = user.name || d;
                return (
                  <div
                    className="tag"
                    key={d.id + Date.now()}
                    onMouseOver={this.tooltip.onMouseOver(user.name)}
                    onMouseLeave={this.tooltip.onMouseOut()}
                  >
                    <span>
                      <strong>{user.name}</strong>
                    </span>
                    {
                      !disabled &&
                      <i className="fa fa-times" onClick={e => {
                        users.splice(index, 1);
                        this.setState({...this.state, users});
                        this.props.onChange(this.state.users, this.state.usersMap);
                      }}/>
                    }
                  </div>
                )
              })
            }
            <SearchBox
              key={Date.now()}
              className="searchBox share-box msq-search-box"
              api="/api/user_search"
              apiParams={{allowExternal: allowExternal, advertiser_id: advertiser_id}}
              placeholder="Search users..."
              searchWithoutKeyword={searchWithoutKeyword}
              closeOnItemClick={true}
              disabled={disabled}
              variantType={excludeRole === "Viewer" ? "planner" : "viewer"}
              itemRenderer={d => {
                let option = d, disabled = false;
                if(excludeRole && d.roles && (d.roles.join(',')) === excludeRole) {
                  disabled = true;
                }
                return (
                  <div className={classnames('item-option', {disabled}, {selected: (users || []).indexOf(d.id) >= 0})} onClick={e => {
                    if(disabled) {
                      return;
                    }
                    if((users || []).indexOf(d.id) >= 0){
                      return;
                    }
                    this.setState({users: [...users, d.id], usersMap: [...usersMap, {
                      name: d.name,
                      id: d.id
                    }]}, () => {
                      this.props.onChange(this.state.users, this.state.usersMap);
                    });
                  }}>
                    <div className="msq-name">{d.name} ({d.id})  {disabled && ` -- User with ${excludeRole} role is not allowed`}</div>
                  </div>
                )
              }}
            />
          </div>
          {
            users.length > 0 &&
            <a className="clearAll" onClick={e => {
              this.setState({...this.state, users: []});
              this.props.onChange([], this.state.usersMap);
            }}>
              <i className="fa fa-times"/> Clear
            </a>
          }
        </FilterTagWrapper>

    )
  }
})

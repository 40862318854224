import React, {Component, useEffect} from 'react';
import {
  intFormatter,
  pctFormatter,
  uniquesFormatter,
  moneyFormatterGenerator,
  getMetricFormatter, moneyFormatter
} from "../utils/formatter";
import {metricMetaData, platformLabelMap} from "../utils/metadata";
import _ from "lodash";
import classNames from "classnames";
import {defaultTooltip, defaultWhiteTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";
import moment from "moment/moment";
import {PacingTooltip, PacingWrapper, KeyMetric} from "./keymetric.style";
import ContentEditable from "react-contenteditable";
import {Hint} from "../uikit";


export default class extends Component {

  constructor(props) {
    super(props);
  }

  calculateDayLeft(data) {
    let {value: status, details} = data;
    if(status === 'DELIVERING') {
      return moment(details.end_date).diff(moment(), 'day') + ' days left';
    }
    return '';
  }

  render() {
    const {data, context, benchmark, cfg = {}, currency = 'SGD', chartStateChanged, displayMode} = this.props;
    if(!data) {
      return null;
    }
    const meta = context.mixedMetaData.find(d => d.key === cfg.metric) || {};
    let formatter = getMetricFormatter(meta, currency);
    let notAvailable = v => false; //[undefined, null].some(d => d === v);
    return (
      <KeyMetric className={cfg.metric}>
        <div>
          <div className="chart-title top">
            <ContentEditable
              disabled={displayMode || window.location.pathname === '/report/view'}
              html={cfg.title || meta.label} // innerHTML of the editable div
              onChange={e => {
                cfg.title = e.target.value;
                chartStateChanged && chartStateChanged({title: e.target.value}, true);
              }}
              tagName='span' // Use a custom HTML tag (uses a div by default)
              onMouseOver={meta.description ? defaultTooltip.onMouseOver({txt: meta.description}) : undefined}
              onMouseLeave={defaultTooltip.onMouseOut()}
            />
          </div>
          <div className="bottom bottom-opacity" data-raw={data.value}>
            {data && notAvailable(data.value) && <span className="no-data">Data Not Available</span>}
            {data && !notAvailable(data.value) && formatter(data.value)}
            {!data && !notAvailable(null) && formatter(null)}
          </div>
          {
            cfg.descriptionVisible !== 'hide' &&
            <>
              {
                !benchmark && data && !!data.target && cfg.metric !== 'pacing' &&   //normal metrics details
                <div  className="desc">
                  Target: {formatter(data.target)}
                  {
                    !isNaN(data.value) && !isNaN(data.target) &&
                    <span> (Achieved {pctFormatter((data.value) / data.target)})</span>
                  }
                </div>
              }
              {
                !benchmark && ['budget', 'spend'].includes(cfg.metric) && data && !!data.breakdown &&  //budget metric details
                <div  className="desc">
                  {data.breakdown.map(d => `${platformLabelMap[d.key]}: $${intFormatter(Math.round(d.value), currency)}`).join(' / ')}
                </div>
              }
              {
                cfg.metric === 'pacing' && data.details && Object.keys(data.details).length > 0 &&
                <div  className="desc">
                  {this.calculateDayLeft(data)}
                </div>
              }
              {
                data ? (data.notes || []).map(note => <div  className="desc">{note}</div>) : null
              }
              {/*{*/}
              {/*  !benchmark && data && !!data.breakdown && cfg.metric === 'pacing' && cfg.platform === 'digital' && //pacing metric details*/}
              {/*  <div className="detail-chart">*/}
              {/*    {this.renderPacing(data.breakdown)}*/}
              {/*  </div>*/}
              {/*}*/}
              {
                !!benchmark && data &&  //benchmark metric details
                <div  className="desc">
                  Benchmark: {formatter(benchmark.value)}
                  {
                    !isNaN(benchmark.value) && !isNaN(data.value) &&
                    data.value !== benchmark.value && <span> (
                      {pctFormatter((data.value - benchmark.value) / benchmark.value)}
                      {data.value > benchmark.value ? 'increased' : (data.value < benchmark.value ? 'declined' : '')}
                      )
                </span>
                  }
                </div>
              }
            </>
          }
        </div>
      </KeyMetric>
    )
  }

  // renderPacing(data) {
  //   const {cfg} = this.props;
  //   const isSinglePlatform = data && data.length === 1;
  //   return (
  //     <PacingWrapper>
  //       {
  //         data.map(d => {
  //           if(!d.target) {
  //             return null;
  //           }
  //           let good = d.actual / d.target >= 0.95;
  //           let actual = Math.min(d.actual, d.total);
  //           return (
  //             <div key={d.key} className="bar-item"
  //                  onMouseOver={defaultWhiteTooltipTop.onMouseOver({tipContent: (
  //                    <>
  //                      {
  //                        d.key === 'tv' &&
  //                        <PacingTooltip>
  //                          <div>Total Spots: &nbsp;<strong>{intFormatter(d.total)}</strong></div>
  //                          <div>Delivered Spots:&nbsp; <strong>{d.actual} ({pctFormatter(d.actual / d.total)})</strong></div>
  //                        </PacingTooltip>
  //                      }
  //                      {
  //                        d.key === 'digital' &&
  //                         <PacingTooltip>
  //                           <div>Total Target Impressions:&nbsp; <strong>{intFormatter(d.total)}</strong></div>
  //                           <div>Projected Impressions: &nbsp;<strong>{intFormatter(d.target)} ({pctFormatter(d.target / d.total)})</strong></div>
  //                           <div>Delivered Impressions:&nbsp; <strong>{intFormatter(d.actual)} ({pctFormatter(d.actual / d.total)})</strong></div>
  //                        </PacingTooltip>
  //                      }
  //                    </>
  //                    )})}
  //                  onMouseLeave={defaultWhiteTooltipTop.onMouseOut()}>
  //               <label>
  //                 {isSinglePlatform && d.key === 'tv' && 'Spots'}
  //                 {isSinglePlatform && d.key === 'digital' && 'Impressions'}
  //                 {isSinglePlatform && d.key === 'radio' && 'Spots'}
  //                 {!isSinglePlatform && <span>{platformLabelMap[d.key]}</span>}
  //                 &nbsp;({pctFormatter(d.actual / d.total)})
  //               </label>
  //               {/*<label>{platformLabelMap[d.key]}</label>*/}
  //               {/*<div className={classNames(`pacing-bar`, {good: good})}>*/}
  //               {/*  <div className="actual" style={{width: pctFormatter(actual / d.total)}}></div>*/}
  //               {/*  <div className="target"  style={{left: pctFormatter(d.target / d.total)}}></div>*/}
  //               {/*</div>*/}
  //             </div>
  //           )
  //         })
  //       }
  //     </PacingWrapper>
  //   )
  // }
}
